@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}


  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }

  :root {
    /* Variables for light mode */
    --tooltip-bg-color-light: #98ECFF; 
    --tooltip-border-color-light: rgba(101, 101, 101, 0.22);
    --tooltip-text-color-light: #000;
    
    /* Variables for dark mode */
    --tooltip-bg-color-dark: transparent; 
    --tooltip-border-color-dark: #98ECFF;
    --tooltip-text-color-dark: #98ECFF;
  }
  
  /* Apply dark mode styles using the dark-mode class */
  .dark-mode {
    --tooltip-bg-color: var(--tooltip-bg-color-dark);
    --tooltip-border-color: var(--tooltip-border-color-dark);
    --tooltip-text-color: var(--tooltip-text-color-dark);
  }
  
  /* Apply light mode styles using the light-mode class */
  :root:not(.dark-mode) {
    --tooltip-bg-color: var(--tooltip-bg-color-light);
    --tooltip-border-color: var(--tooltip-border-color-light);
    --tooltip-text-color: var(--tooltip-text-color-light);
  }
  
  .TooltipContent {
    background-color: var(--tooltip-bg-color);
    border: 1px solid var(--tooltip-border-color);
    color: var(--tooltip-text-color);
    border-radius: 0;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 100px;
  }
  
  *::-webkit-scrollbar-track {
    background: #151518;
    border-radius: 0px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #3a3c41;
    border-radius: 0px;
    border: 3px solid var(--primary);
  }